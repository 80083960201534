import { Outlet, useLocation } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import Header from './Header'
import Navbar from './Navbar'
import Footer from './Footer'
import ContentContainer from './ContentContainer'

interface MainContentContainerProps {
  height: number
}

const MainContentContainer = styled.main<MainContentContainerProps>`
  min-height: ${({ height }) => height}px;
`

const ContentContainerStyled = styled(ContentContainer)`
  padding: 1.5rem;
`

const PageLayout = () => {
  const [headerHeight, setHeaderHeight] = useState<number>(0)
  const [navbarHeight, setNavbarHeight] = useState<number>(0)
  const [footerHeight, setFooterHeight] = useState<number>(0)

  const location = useLocation()

  const contentHeight = useMemo(() => {
    return window.innerHeight - headerHeight - navbarHeight - footerHeight
  }, [footerHeight, headerHeight, navbarHeight])

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [location])

  return (
    <>
      <Header onLoad={(e) => setHeaderHeight(e.currentTarget.clientHeight)} />

      <Navbar ref={(e) => setNavbarHeight(e?.clientHeight ?? 0)} />

      <MainContentContainer height={contentHeight}>
        <section>
          <ContentContainerStyled>
            <Outlet />
          </ContentContainerStyled>
        </section>
      </MainContentContainer>

      <Footer ref={(e) => setFooterHeight(e?.clientHeight ?? 0)} />
    </>
  )
}

export default PageLayout
