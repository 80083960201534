import styled from 'styled-components'
import { ChangeEventHandler, FormEventHandler, useState } from 'react'

import Input from './Input'
import Textarea from './Textarea'

const INITIAL_DATA: FormData = {
  name: '',
  company: '',
  phone: '',
  email: '',
  subject: '',
  description: '',
}

interface ContactsFormProps {
  loading?: boolean
  onSubmit?: (data: FormData) => Promise<{ success: boolean }>
}

export interface FormData {
  [name: string]: string
}

const Container = styled.form`
  display: grid;
  row-gap: 0.5rem;
`

const SubmitButtonContainer = styled.div`
  padding-top: 1rem;
  display: grid;
  justify-items: center;
`

const SubmitButton = styled.button.attrs({
  type: 'submit',
})`
  display: block;
  max-width: 9.5rem;
  width: 100%;
  height: 2.25rem;
  background-color: ${({ theme }) => theme.colors.color8};
  text-align: center;
  font-weight: 700;
  border-radius: 0.25rem;
  overflow: hidden;

  &:enabled {
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 1rem ${({ theme }) => theme.colors.color9};
    }
  }
`

const ContactsForm = ({ loading, onSubmit }: ContactsFormProps) => {
  const [data, setData] = useState<FormData>(INITIAL_DATA)

  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    setData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }))
  }

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()

    const res = await onSubmit?.(data)

    if (!res?.success) {
      return
    }

    setData(INITIAL_DATA)
  }

  return (
    <Container onSubmit={handleSubmit}>
      <Input
        name="name"
        value={data.name}
        label="Vardas"
        type="text"
        required
        disabled={loading}
        onChange={handleChange}
      />

      <Input name="company" value={data.company} label="Įmonė" type="text" disabled={loading} onChange={handleChange} />

      <Input name="phone" value={data.phone} label="Telefonas" type="text" disabled={loading} onChange={handleChange} />

      <Input
        name="email"
        value={data.email}
        label="E. paštas"
        type="email"
        required
        disabled={loading}
        onChange={handleChange}
      />

      <Input
        name="subject"
        value={data.subject}
        label="Tema"
        type="text"
        required
        disabled={loading}
        onChange={handleChange}
      />

      <Textarea
        name="description"
        rows={10}
        value={data.description}
        label="Kas Jus domina?"
        required
        disabled={loading}
        onChange={handleChange}
      />

      <SubmitButtonContainer>
        <SubmitButton disabled={loading}>Siųsti užklausą</SubmitButton>
      </SubmitButtonContainer>
    </Container>
  )
}

export default ContactsForm
