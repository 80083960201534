import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import emailjs from '@emailjs/browser'

import Home from './Home'
import SnowCleaning from './SnowCleaning'
import Contacts from './Contacts'
import PageLayout from '../components/PageLayout'
import PageLoader from '../components/PageLoader'
import { cacheImages } from '../utilities/functions'

emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY)

const IMGS = [
  require('../assets/images/brand-logo.png'),
  require('../assets/images/info-call.png'),
  require('../assets/images/snow-card.png'),
]

const router = createBrowserRouter([
  {
    element: <PageLayout />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/sniego-valymas',
        element: <SnowCleaning />,
      },
      {
        path: '/kontaktai',
        element: <Contacts />,
      },
      {
        path: '*',
        element: <Navigate to="/" replace />,
      },
    ],
  },
])

const Navigation = () => {
  const [loading, setLoading] = useState<boolean>(true)

  const loadImages = async () => {
    try {
      setLoading(true)

      await cacheImages(IMGS)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadImages()
  }, [])

  if (loading) {
    return <PageLoader />
  }

  return <RouterProvider router={router} />
}

export default Navigation
