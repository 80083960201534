import { FadeLoader } from 'react-spinners'
import styled from 'styled-components'

import { theme } from '../utilities/theme'

const Container = styled.div`
  height: 100vh;
  display: grid;
  align-items: center;
  justify-content: center;
`

const PageLoader = () => {
  return (
    <Container>
      <FadeLoader color={theme.colors.color8} />
    </Container>
  )
}

export default PageLoader
