import { NavLink, To } from 'react-router-dom'
import styled from 'styled-components'
import { forwardRef } from 'react'

import ContentContainer from './ContentContainer'
import { theme } from '../utilities/theme'

const LINKS: { to: To; title: string }[] = [
  {
    to: '/sniego-valymas',
    title: 'Sniego valymas',
  },
  {
    to: '/kontaktai',
    title: 'Kontaktai',
  },
]

const MainContainer = styled.nav`
  background-color: ${({ theme }) => theme.colors.color3};
  position: sticky;
  top: 0;
`

const ContentContainerStyled = styled(ContentContainer)`
  @media ${({ theme }) => theme.queries.mobile} {
    padding: 0;
  }
`

const LinksContainer = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: end;

  @media ${({ theme }) => theme.queries.mobile} {
    grid-template-columns: 1fr;
  }
`

const NavLinkStyled = styled(NavLink)`
  height: 3rem;
  display: grid;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.color1};
  padding: 0 1.5rem;

  &:hover {
    background-color: ${({ theme }) => theme.colors.color4};
  }
`

const Divider = styled.div`
  display: none;

  @media ${({ theme }) => theme.queries.mobile} {
    display: block;
    height: 0.063rem;
    background-color: ${({ theme }) => theme.colors.color4};
  }
`

const Navbar = forwardRef<HTMLElement>((_, ref) => {
  return (
    <MainContainer ref={ref}>
      <ContentContainerStyled>
        <LinksContainer>
          {LINKS.map((link, index) => (
            <li key={index}>
              {index > 0 && <Divider />}

              <NavLinkStyled
                to={link.to}
                style={({ isActive }) => (isActive ? { backgroundColor: theme.colors.color4 } : {})}
              >
                {link.title}
              </NavLinkStyled>
            </li>
          ))}
        </LinksContainer>
      </ContentContainerStyled>
    </MainContainer>
  )
})

export default Navbar
