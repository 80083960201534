import { DefaultTheme } from 'styled-components'

const theme: DefaultTheme = {
  colors: {
    color1: '#ffffff',
    color2: '#808080', // rgb(128, 128, 128)
    color3: '#343538', // rgb(52, 53, 56)
    color4: '#ffffff1a', // rgba(255, 255, 255, 0.1)
    color5: '#bfbfbf', // rgb(191, 191, 191)
    color6: '#0000ee', // rgb(0, 0, 238)
    color7: '#ff0000', // rgb(255, 0, 0)
    color8: '#fcb520', // rgb(252, 181, 32)
    color9: '#34353880',
  },
  queries: {
    mobile: '(max-width: 767.98px)',
    tablet: '(min-width: 768px) and (max-width: 991.98px)',
    desktop: '(min-width: 992px)',
  },
}

export { theme }
