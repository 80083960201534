import styled from 'styled-components'

const ContentContainer = styled.div`
  max-width: 71.25rem;
  width: 100%;
  margin: 0 auto;
  padding: 0 1.5rem;
`

export default ContentContainer
