import styled from 'styled-components'
import { forwardRef, useEffect, useState } from 'react'

import ContentContainer from './ContentContainer'
import WeatherCard from './WeatherCard'
import { City, WeatherData, fetchWeatherData } from '../utilities/functions'

const CITIES: City[] = [
  { name: 'Kaunas', lat: 54.9, lon: 23.9 },
  { name: 'Marijampolė', lat: 54.5667, lon: 23.35 },
]

const MainContainer = styled.footer`
  background-color: ${({ theme }) => theme.colors.color3};
`

const ContentContainerStyled = styled(ContentContainer)`
  padding: 1.5rem;
`

const CitiesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;

  @media ${({ theme }) => theme.queries.mobile} {
    grid-template-columns: 1fr;
  }
`

const CopyrightContainer = styled.div`
  padding-top: 1.5rem;
`

const Copyright = styled.p`
  color: ${({ theme }) => theme.colors.color1};
  text-align: center;

  strong {
    font-weight: 700;
  }
`

const Footer = forwardRef<HTMLElement>((_, ref) => {
  const [weatherData, setWeatherData] = useState<WeatherData>({})

  const getWeatherData = async () => {
    try {
      const data = await fetchWeatherData(CITIES)

      setWeatherData(data)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getWeatherData()
  }, [])

  return (
    <MainContainer ref={ref}>
      <ContentContainerStyled>
        <CitiesContainer>
          {CITIES.map((city, index) => (
            <WeatherCard key={index} city={city.name} temp={weatherData[city.name]?.temp} />
          ))}
        </CitiesContainer>

        <CopyrightContainer>
          <Copyright>
            © {new Date().getFullYear()} <strong>UAB "GBM INVEST"</strong> Visos teisės saugomos
          </Copyright>
        </CopyrightContainer>
      </ContentContainerStyled>
    </MainContainer>
  )
})

export default Footer
