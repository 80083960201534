import React from 'react'
import ReactDOM from 'react-dom/client'
import { ThemeProvider } from 'styled-components'

import './index.css'
import Navigation from './pages'
import { theme } from './utilities/theme'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Navigation />
    </ThemeProvider>
  </React.StrictMode>,
)
