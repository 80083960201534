import styled from 'styled-components'

interface PageTitleProps {
  home?: boolean
  title?: string
  description?: string
}

interface TitleProps {
  home?: boolean
}

interface DescriptionProps {
  home?: boolean
}

const Title = styled.h1<TitleProps>`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.color3};
  font-weight: 700;

  ${({ home }) =>
    !!home &&
    `
    font-size: 2rem;
    text-align: center;
    text-transform: uppercase;
  `}

  @media ${({ theme }) => theme.queries.mobile} {
    text-align: center;
  }
`

const Divider = styled.div`
  height: 0.063rem;
  background-color: ${({ theme }) => theme.colors.color5};
  margin: 1.5rem 0;
`

const Description = styled.p<DescriptionProps>`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.color2};

  ${({ home }) =>
    !!home &&
    `
    text-align: center;
  `}

  @media ${({ theme }) => theme.queries.mobile} {
    text-align: center;
  }
`

const PageTitle = ({ home, title, description }: PageTitleProps) => {
  return (
    <div>
      {!!title && <Title home={home}>{title}</Title>}

      <Divider />

      {!!description && <Description home={home}>{description}</Description>}
    </div>
  )
}

export default PageTitle
