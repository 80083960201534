import { TextareaHTMLAttributes } from 'react'
import styled from 'styled-components'

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string
}

const Container = styled.div`
  display: grid;
  row-gap: 0.25rem;
`

const Label = styled.label`
  color: ${({ theme }) => theme.colors.color3};
  font-weight: 700;

  span {
    color: ${({ theme }) => theme.colors.color7};
  }
`

const TextareaStyled = styled.textarea`
  border: 0.125rem solid ${({ theme }) => theme.colors.color5};
  border-radius: 0.25rem;
  overflow: hidden;
  display: block;
  width: 100%;
  color: ${({ theme }) => theme.colors.color3};
  padding: 0.5rem;
  overflow-wrap: break-word;

  &:focus {
    border: 0.125rem solid ${({ theme }) => theme.colors.color3};
  }
`

const Textarea = ({ label, ...props }: TextareaProps) => {
  return (
    <Container>
      {!!label && (
        <Label>
          {label}
          {props.required ? ' ' : ''}
          {!!props.required && <span>*</span>}
        </Label>
      )}

      <TextareaStyled {...props} />
    </Container>
  )
}

export default Textarea
