import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { MdPhone, MdEmail } from 'react-icons/md'

const Title = styled.div`
  font-size: 1.125rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.color3};
`

const ContactsContainer = styled.div`
  padding-top: 0.5rem;
  display: grid;
  row-gap: 0.5rem;
`

const ContactContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: start;
  align-items: center;
  column-gap: 0.5rem;
`

const LinkStyled = styled(Link)`
  color: ${({ theme }) => theme.colors.color6};
`

const PhoneIcon = styled(MdPhone).attrs(({ theme }) => ({
  size: '1.25rem',
  color: theme.colors.color3,
}))``

const EmailIcon = styled(MdEmail).attrs(({ theme }) => ({
  size: '1.25rem',
  color: theme.colors.color3,
}))``

const GetInTouch = () => {
  return (
    <div>
      <Title>Susisiekite:</Title>

      <ContactsContainer>
        <ContactContainer>
          <PhoneIcon />

          <LinkStyled to="tel:+37061512144">+37061512144</LinkStyled>
        </ContactContainer>

        <ContactContainer>
          <EmailIcon />

          <LinkStyled to="mailto:mykolina@gmail.com">mykolina@gmail.com</LinkStyled>
        </ContactContainer>
      </ContactsContainer>
    </div>
  )
}

export default GetInTouch
