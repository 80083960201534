import styled from 'styled-components'
import { MdCheck } from 'react-icons/md'

interface ItemsListProps {
  items?: string[]
}

const ItemsContainer = styled.div`
  display: grid;
  row-gap: 0.5rem;
`

const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.5rem;
`

const ItemText = styled.span`
  color: ${({ theme }) => theme.colors.color2};
`

const CheckIcon = styled(MdCheck).attrs(({ theme }) => ({
  size: '1.5rem',
  color: theme.colors.color8,
}))``

const ItemsList = ({ items = [] }: ItemsListProps) => {
  return (
    <ItemsContainer>
      {items.map((item, index) => (
        <ItemContainer key={index}>
          <CheckIcon />

          <ItemText>{item}</ItemText>
        </ItemContainer>
      ))}
    </ItemsContainer>
  )
}

export default ItemsList
