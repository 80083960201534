import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { HTMLAttributes } from 'react'

import BrandLogoImg from '../assets/images/brand-logo.png'
import InfoCallImg from '../assets/images/info-call.png'
import ContentContainer from './ContentContainer'

interface HeaderProps extends HTMLAttributes<HTMLElement> {}

const ContentContainerStyled = styled(ContentContainer)`
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  padding: 1.5rem;

  @media ${({ theme }) => theme.queries.mobile} {
    grid-template-columns: 1fr;
  }
`

const BrandLogoImgStyled = styled.img.attrs({
  src: BrandLogoImg,
  alt: 'brand-logo-img',
})`
  height: auto;
  width: 24rem;
  max-width: 100%;

  @media ${({ theme }) => theme.queries.mobile} {
    width: calc(100vw - 3rem);
  }
`

const InfoCallImgStyled = styled.img.attrs({
  src: InfoCallImg,
  alt: 'info-call-img',
})`
  height: auto;
  width: 31rem;
  max-width: 100%;

  @media ${({ theme }) => theme.queries.mobile} {
    width: calc(100vw - 3rem);
  }
`

const Header = (props: HeaderProps) => {
  return (
    <header {...props}>
      <ContentContainerStyled>
        <Link to="/">
          <BrandLogoImgStyled />
        </Link>

        <Link to="tel:+37061512144">
          <InfoCallImgStyled />
        </Link>
      </ContentContainerStyled>
    </header>
  )
}

export default Header
