import styled from 'styled-components'

interface WeatherCardProps {
  city?: string
  temp?: number
}

const MainContainer = styled.div`
  display: grid;
  row-gap: 0.5rem;
`

const CardContainer = styled.div`
  height: 6.5rem;
  border-radius: 0.25rem;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.color4};
  display: grid;
  align-items: center;
  justify-content: center;
`

const City = styled.span`
  color: ${({ theme }) => theme.colors.color1};
  text-transform: uppercase;
  text-align: center;
`

const Temperature = styled.span`
  color: ${({ theme }) => theme.colors.color1};
  font-size: 4rem;
`

const WeatherCard = ({ city, temp }: WeatherCardProps) => {
  return (
    <MainContainer>
      {!!city && <City>{city}</City>}

      <CardContainer>
        <Temperature>{`${typeof temp === 'number' ? temp : '-'}°`}</Temperature>
      </CardContainer>
    </MainContainer>
  )
}

export default WeatherCard
