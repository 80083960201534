import styled from 'styled-components'
import { Link } from 'react-router-dom'

import PageTitle from '../../components/PageTitle'
import SnowCardImg from '../../assets/images/snow-card.png'

const SnowCardContainer = styled.div`
  padding-top: 1.5rem;
  display: grid;
  justify-content: center;
`

const SnowCard = styled.img.attrs({
  src: SnowCardImg,
  alt: 'snow-cleaning-card',
})`
  border-radius: 0.25rem;
  overflow: hidden;
  object-fit: cover;
  width: 33rem;
  max-width: 100%;
  height: auto;

  &:hover {
    box-shadow: 0 0 1rem ${({ theme }) => theme.colors.color9};
  }

  @media ${({ theme }) => theme.queries.mobile} {
    width: calc(100vw - 3rem);
  }
`

const Home = () => {
  return (
    <>
      <PageTitle
        home
        title="Profesionalios valymo paslaugos"
        description="Geriausios kainos garantija. Be tarpininkų. Ilgametė patirtis. Aptarnavimo kultūra. Konsultavimas."
      />

      <SnowCardContainer>
        <Link to="/sniego-valymas">
          <SnowCard />
        </Link>
      </SnowCardContainer>
    </>
  )
}

export default Home
